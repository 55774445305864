<template>
  <div class="wrap">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <ayl-table :table="table"></ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransportAddress",
  data() {
    return {
      nav: [
        { name: "系统管理" },
        { name: "托运地址", path: "/system-manage/transport-address/list" },
      ],
      table: {
        api: this.$api.consignOrder_getAddressSummaryList,
        query: {
          queryContent: null,
        },
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "用户名称/手机号码",
          },
        ],
        columns: [
          {
            title: "序号",
            width: "60px",
            align: "center",
            $index: "index",
          },
          {
            title: "小程序用户",
            showTooltip: true,
            key: "appletsUser",
          },
          {
            title: "手机号码",
            key: "phone",
          },
          {
            title: "地址数量",
            key: "adressNum",
          },
          {
            title: "属性",
            key: "attributeDesc",
          },
          {
            title: "操作",
            width: "90px",
            align: "center",
            render: (h, ctx) => {
              return h("div", [
                h(
                  "el-button",
                  {
                    props: {
                      type: "text",
                    },
                    style: {
                      color: "#0575E6",
                    },
                    on: {
                      click: () => this.goAddrDetails(ctx),
                    },
                  },
                  "地址明细"
                ),
              ]);
            },
          },
        ],
      },
    };
  },
  watch: {
    $route() {
      this.$search(this.table);
    },
  },
  methods: {
    goAddrDetails(val) {
      if (val.row.attribute / 1 === 0) {
        this.$router.push({
          path: "/system-manage/transport-address/public",
          query: {
            id: val.row.id,
            attribute: val.row.attribute,
          },
        });
      } else {
        this.$router.push({
          path: "/system-manage/transport-address/person",
          query: {
            id: val.row.id,
            attribute: val.row.attribute,
          },
        });
      }
    },
  },
};
</script>